import Vue from 'vue';

Vue.use(require('vue-cookies'))

const that = Vue.prototype

const state = Vue.observable({
    // axiosCounter:0,
    // marketType:[],
    notification:[],
    isFooter:true,
    changelanmodal:false,
    // token: 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJuaW1hMjE0Mi5wMzBAZ21haWwuY29tIiwiaWF0IjoxNjQ2NzIxODkxLCJleHAiOjkyMjMzNzIwMzY4NTQ3NzV9.Ba0NBdJ6pTWBBIW49-mCmdLplzqF-ZaqbbEQLG59xnA',
    token: localStorage.token || Vue.$cookies.get('--abcd14589') || false,
    otptoken:null,
    loading: false,
    gotten: false,
    infoGotten: false,
    loop: process.env.NODE_ENV !== 'development',
    time: 5000,
    orderChanged:0,
    requestChanged:0,
    coins:false,
    progress:0,
    dark: localStorage.dark === 'true',
    messages:0,
    info:{},
    userInfo: {
        firstName: '',
        lastName: '',
        birthDay:'',
        fullName:'',
        address:'',
        email: '',
        nationalCode:'',
        mobileNumber:'',
        telePhone:'',
        postalCode:'',
        bankAccounts:[],
        remember: false,
        towStepAuthenticationType: 'EMAIL',
        authenticationType: '',
        authorized: false,
        pending: false,
        supervisor: false,
        userAccountLevel: '',
        totalTradesVolume: 0,
        fee: 0.4,
    },

    shopInfo: {
        minSell: 0,
        maxBuy: 0,
        lastPrice: 0,
    },

    buyAmount:0,
    buyPrice:0,
    sellAmount:0,
    sellPrice:0,
    buyOther:false,
})

that.state = state
export default state