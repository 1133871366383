<template>
        <div class="overlay">
            <div class="spinner"></div>
            <div class="label">Loading</div>
        </div>
</template>

<script>
    export default {
        name:'Loading',

    }
</script>

<style lang="scss" scoped>
@-webkit-keyframes SPINNER {
  0%   { -webkit-transform: translate(-50%,-50%) rotate(0deg); }
  100% { -webkit-transform: translate(-50%,-50%) rotate(360deg); }
}
@-moz-keyframes SPINNER {
  0%   { -moz-transform: translate(-50%,-50%) rotate(0deg); }
  100% { -moz-transform: translate(-50%,-50%) rotate(360deg); }
}
@-ms-keyframes SPINNER {
  0%   { -ms-transform: translate(-50%,-50%) rotate(0deg); }
  100% { -ms-transform: translate(-50%,-50%) rotate(360deg); }
}
@-o-keyframes SPINNER {
  0%   { -o-transform: translate(-50%,-50%) rotate(0deg); }
  100% { -o-transform: translate(-50%,-50%) rotate(360deg); }
}
@keyframes SPINNER {
  0%   { transform: translate(-50%,-50%) rotate(0deg); }
  100% { transform: translate(-50%,-50%) rotate(360deg); }
}

@-webkit-keyframes LABEL {
  0%   { opacity: 1.0; -webkit-transform: translate(-50%,-50%) scale(1.0); }
  5%   { opacity: 0.5; -webkit-transform: translate(-50%,-50%) scale(0.5); }
  95%  { opacity: 0.5; -webkit-transform: translate(-50%,-50%) scale(0.5); }
  100% { opacity: 1.0; -webkit-transform: translate(-50%,-50%) scale(1.0); }
}
@-moz-keyframes LABEL {
  0%   { opacity: 1.0; -moz-transform: translate(-50%,-50%) scale(1.0); }
  5%   { opacity: 0.5; -moz-transform: translate(-50%,-50%) scale(0.5); }
  95%  { opacity: 0.5; -moz-transform: translate(-50%,-50%) scale(0.5); }
  100% { opacity: 1.0; -moz-transform: translate(-50%,-50%) scale(1.0); }
}
@-ms-keyframes LABEL {
  0%   { opacity: 1.0; -ms-transform: translate(-50%,-50%) scale(1.0); }
  5%   { opacity: 0.5; -ms-transform: translate(-50%,-50%) scale(0.5); }
  95%  { opacity: 0.5; -ms-transform: translate(-50%,-50%) scale(0.5); }
  100% { opacity: 1.0; -ms-transform: translate(-50%,-50%) scale(1.0); }
}
@-o-keyframes LABEL {
  0%   { opacity: 1.0; -o-transform: translate(-50%,-50%) scale(1.0); }
  5%   { opacity: 0.5; -o-transform: translate(-50%,-50%) scale(0.5); }
  95%  { opacity: 0.5; -o-transform: translate(-50%,-50%) scale(0.5); }
  100% { opacity: 1.0; -o-transform: translate(-50%,-50%) scale(1.0); }
}
@keyframes LABEL {
  0%   { opacity: 1.0; transform: translate(-50%,-50%) scale(1.0); }
  5%   { opacity: 0.5; transform: translate(-50%,-50%) scale(0.5); }
  95%  { opacity: 0.5; transform: translate(-50%,-50%) scale(0.5); }
  100% { opacity: 1.0; transform: translate(-50%,-50%) scale(1.0); }
}

*, *:before, *:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  vertical-align: top;
}
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,1.0);
  z-index: 10000;
}
.overlay .spinner,
.overlay .spinner:before,
.overlay .spinner:after {
  border: 10px solid rgba(0,0,0,0);
  border-top: 10px solid #ccc;
  border-bottom: 10px solid #ccc;
  border-radius: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
}
.overlay .spinner:before,
.overlay .spinner:after {
  content: "";
}
.overlay .spinner {
  width: 100px;
  height: 100px;
  -webkit-animation: SPINNER 5s linear infinite;
  -moz-animation: SPINNER 5s linear infinite;
  -ms-animation: SPINNER 5s linear infinite;
  -o-animation: SPINNER 5s linear infinite;
  animation: SPINNER 5s linear infinite;
}
.overlay .spinner:before {
  width: 80px;
  height: 80px;
  -webkit-animation: SPINNER 10s linear infinite;
  -moz-animation: SPINNER 10s linear infinite;
  -ms-animation: SPINNER 10s linear infinite;
  -o-animation: SPINNER 10s linear infinite;
  animation: SPINNER 10s linear infinite;
}
.overlay .spinner:after {
  width: 60px;
  height: 60px;
  -webkit-animation: SPINNER 5s linear infinite;
  -moz-animation: SPINNER 5s linear infinite;
  -ms-animation: SPINNER 5s linear infinite;
  -o-animation: SPINNER 5s linear infinite;
  animation: SPINNER 5s linear infinite;
}
.overlay .label {
  color: #ccc;
  text-transform: uppercase;
  font-family: sans-serif;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-animation: LABEL 5s linear infinite;
  -moz-animation: LABEL 5s linear infinite;
  -ms-animation: LABEL 5s linear infinite;
  -o-animation: LABEL 5s linear infinite;
  animation: LABEL 5s linear infinite;
}
button[type="button"] {
  padding: 0.5rem 1rem;
  position: absolute;
  z-index: 1;
  bottom: 10px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

/* Customize */
.overlay {
  background: rgba(0, 0, 0, 0.32);
  backdrop-filter: blur(5px);
}
.overlay .spinner,
.overlay .spinner:before,
.overlay .spinner:after {
  border-top-color: var(--primary) !important;
  border-bottom-color: rgba(0,255,255,1.0) !important;
}
.overlay .label {
  color: rgba(0,255,255,1.0);
}
</style>