<template>
    <form @submit.prevent="handleSubmit" class="register-container">
        <h2> تایید کد </h2>
        <div class="inputs-container">


            <div class="show-email" style="justify-content:space-between">
                <span class="title">
                        کد ارسال شده به
                        {{$athenType[$route.params.authType]}}
                        خود را وارد کنید
                </span>
                <span v-if="counter > 0" class="counter"> {{Math.floor(counter/60)}}:{{counter%60}} </span>
                <span @click="resendBtn" v-else class="counter resend"> ارسال مجدد کد </span>
            </div>




            <div class="show-email">
                <inline-svg class="svg" :src="require('../../assets/Icons/Email.svg')" />
                <span class="email">
                    {{ $route.params.authType == 'EMAIL' ?  otp.email : state.userInfo.mobilenumber}}
                </span>
            </div>




           <CodeInput 
                    :loading="false" 
                    class="verif-code" 
                    v-on:change="onChange" 
                    @complete="handleSubmit"
                    
            />

        </div>


        <div class="btns">
            <button :disabled="otp.code.length !== 6" class="submit"> ثبت و ادامه </button>
        </div>


    </form>
</template>

<script>
import CodeInput from "vue-verification-code-input";
import {reset,paste} from "@/Lib/reuseableFunction";
    export default {
        name:'Otp',
        components:{
            CodeInput,

        },
        data() {
            return {
                counter:90,
                timeOut:null,
                otp: {
                    code: '',
                    email: this.state.userInfo.email || this.$cookies.get('username')
                }
            }
        },
        methods:{
            onChange(v) {
                this.otp.code = v
            },
            countDownTimer() {
                if (this.counter > 0) {
                    this.timeOut = setTimeout(() => {
                        this.counter--
                        this.countDownTimer()
                    }, 1000)
                } else {
                    this.$error('زمان شما به اتمام رسید','')
                }
            },
            async resendBtn() {
                this.state.loading = true
                this.reset()
                await this.$axios.post(`/users/resend-otp-code?email=${this.$cookies.get('username')}`)
                this.$error('کد جدید به ' + this.$athenType[this.$route.params.authType] + ' شما ارسال شد.', '', 'success')
                this.$cookies.set('username', this.state.userInfo.email)
                if (this.countdown === 0) {
                    this.countdown = 90
                    this.countDownTimer()
                }

            },
            handleSubmit(){
                if(this.$route.name=='OtpLogin'){
                    // console.log(this.$route.name);
                    this.submitAuth(this.otp.code)
                }else if(this.$route.name == 'OtpForget'){
                    // console.log(this.$route.name);
                    this.submitForget(this.otp.code)
                }
            },
            async submitAuth(e) {
                if (!this.disabled) {
                    this.otp.code=e;
                    this.state.loading = true
                    const res = await this.$axios.post('/users/check-otp-code', this.otp).catch(()=>{})
                    if (res.token) {
                        await this.$setToken(res.token)
                        await this.$router.push({name: 'Account'})
                    }
                }
            },
            async submitForget(e) {
                if (!this.disabled) {
                    this.otp.code=e;
                    this.state.loading = true
                    const res = await this.$axios.post('/users/v2/check-otp-password', this.otp)
                    if (res.token) {
                        this.state.otptoken=res.token
                        await this.$router.push({name: 'NewPass'})
                    }
                }
            },
            reD(){
                if(this.$route.name=='OtpLogin'){
                    this.$router.push({name:'Dashboard'})
                }else if(this.$route.name=='OtpForget'){
                    this.$router.push('/newpass')
                } 
            },
            reset,
            paste,
        },
        beforeDestroy() {
            clearTimeout(this.timeOut)
        },
        mounted (){
            if (this.$route.params.authType !== 'GOOGLE_AUTHENTICATOR') {
                this.countDownTimer()
            }
        },
    }
</script>

<style lang="scss" scoped>
.forgetpass{
    color: var(--primary);
    font-weight: 500;
    font-size: clamp(14px,2vw,16px);
}




.go-login{
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: clamp(12px,1vw,14px);
    a{
        color: var(--primary);
    }
}




.btns{
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    width: 100%;
    align-items: center;
    .submit{
        width: 100%;
    }
    .google{
        width: 100%;
        background: none !important;
        color: var(--primary) !important;
        border: 1px solid var(--primary);
    }
}






.label-container{
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    .pass-svg{
        cursor: pointer;
    }
    .svg{
        width: 24px !important;
        height: 24px !important;
    }
    .warn{
        color: var(--red);
        font-weight: 300;
        font-size: clamp(10px,1vw,12px);
    }
    .svg-active{
        fill: var(--primary);
    }
    .svg-error{
        fill: var(--red) !important;
    }
    .div-active{

            opacity: 1 !important;
            box-shadow: inset 0px -2px 0px #6E78FF;
    }
    .div-error{
            opacity: 1 !important;
            box-shadow: inset 0px -2px 0px var(--red);
    }

}



.inputs-container{
    display: flex;
    flex-direction: column;
    row-gap: 23px;
    width: 100%;
    padding-bottom: 18px;
    .title{
        font-weight: 500;
        font-size: clamp(14px,2vw,16px);
        color: var(--black);
    }
    .show-email{
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 6px;
        .resend{
            cursor: pointer;

        }
        .counter{
            color: var(--primary);
            font-size: clamp(12px,1vw,14px);
        }
        .email{
            color: var(--black);
            font-weight: 400;
            font-size: clamp(10px,1vw,12px);
        }
    }
}



.register-container{
    width: 100%;
    background: var(--homepage-bg);
    box-shadow: 0px 0px 8px -1px rgba(65, 70, 192, 0.2);
    border-radius: 12px;
    padding: 24px 12px;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    align-items: flex-start;
    h2{
        color: var(--darker-primary);
        font-weight: 700;
        font-size: clamp(24px,3vw,28px);
        align-self: center;
    }
}
@media only screen and(max-width:1700px){
    .register-container{
        max-width: 500px;
    }
}
</style>