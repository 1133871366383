<template>
  <div id="app">
    <Loading  v-if="state.loading"/>
    <Navbar />
    <router-view />
    <Footer v-if="dontShow"/>
  </div>
</template>

<script>
import Footer from './components/PublicComponents/Footer.vue'
import Loading from './components/PublicComponents/Loading.vue'
import Navbar from './components/PublicComponents/Navbar.vue'

export default {
  components: { Navbar, Footer, Loading },
  name: 'App',
  computed:{
    dontShow(){
      return this.$route.name !== 'Login' && this.$route.name !== 'Register' && this.$route.name !== 'OtpLogin' && this.$route.name !== 'ForgetPass' && this.$route.name !== 'OtpForget' && this.$route.name !== 'NewPass' && !this.$route.path.includes('Dashboard') && !this.$route.path.includes('dashboard')  && !this.$route.path.includes('advancetrade')  && !this.$route.path.includes('Advancetrade')
    }
  },
  mounted(){
    this.state.loading=true
  }
}
</script>

<style scoped lang="scss">
</style>
