<template>
    <div class="header-container">
        
        <div class="show-coin">
                <div class="coin">
                                        <img :src="'/coins/'+data.relatedCoin+'.png'" alt="">
                                        <div class="unit remove2">
                                            <span class="faunit">
                                                {{$coinLabel[data.relatedCoin]}}
                                            </span>
                                            <span class="enunit">
                                                {{data.relatedCoin}}
                                            </span>
                                        </div>
                </div>


                <div class="seprater"></div>


                <div class="pricebtns">
                                        <button @click="$router.push('/advancetrade/TOMAN/'+$route.params.tradeFrom)" :class="{'select':$route.params.tradeTo=='TOMAN'}" class="toman"> تومان </button>
                                        <button  @click="$router.push('/advancetrade/TETHER/'+$route.params.tradeFrom)" :class="{'select':$route.params.tradeTo=='TETHER'}" class="tether"> تتر </button>
                </div>
        </div>

        <table>
            <tr class="head">
                <td class="bold-td"> قیمت لحظه ای </td>
                <td class="remove"> بیشترین قیمت </td>
                <td class="remove"> کمترین قیمت </td>
                <td> تغییرات </td>
                <td class="remove2"> حجم بازار </td>
            </tr>
            <tr class="body">
                <td class="green"> {{$toLocal(data.lastPrice,$decimal[$route.params.tradeTo])}} </td>
                <td class="remove green"> {{$toLocal(data.maxPrice,$decimal[$route.params.tradeTo])}} </td>
                <td class="remove fail"> {{$toLocal(data.minPrice,$decimal[$route.params.tradeTo])}}  </td>
                <td> 
                    <span v-if="data.last24HourChange < 0" class="fail"> {{$toLocal(Math.abs(data.last24HourChange),2)}}%- </span>    
                    <span v-if="data.last24HourChange > 0" class="green"> {{$toLocal(Math.abs(data.last24HourChange),2)}}%+ </span>
                </td>
                <td class="remove2"> {{$toLocal(data.last24HourVolume,$decimal[$route.params.tradeTo])}} </td>
            </tr>
        </table>




    </div>
</template>

<script>
    export default {
        name:'P2PHeader',
        data() {
            return {
                data:[],
            }
        },
        methods: {
            async getTradeHeader() {
                const [res,] = await this.$http.get('/trades/market-info', {
                    params: {
                        weeklyDataIncluded: false,
                        market_type: this.$route.params.tradeFrom + '_' + this.$route.params.tradeTo
                    }
                })
                this.loading = false
                if(res){
                    this.data = res.content[0] || this.data
                    this.state.shopInfo.lastPrice = this.data.lastPrice
                    this.state.shopInfo.change24 = this.data.last24HourChange
                    // document.title = `بازار ${this.$coinLabel[this.tradeFrom]} - ${this.$coinLabel[this.tradeTo]}` + ' ｜ ' + this.$toLocal(this.data?.lastPrice,this.precision)
                }
            },
        },
        mounted() {
            this.getTradeHeader();
            console.log(this.$route.params.tradeTo);
        },

    }
</script>

<style lang="scss" scoped>
.show-coin{
    display: flex;
    flex-direction: row;
    column-gap: 24px;
}


table{
    .green{
    color: var(--green) !important;
}
    .bold-td{
        font-weight: bold !important;
        color: var(--a-hover) !important;
    }
    width: 100%;
    .head{
        td{
            font-weight: 400;
            font-size: clamp(10px,1vw,12px);
            color: var(--a-hover);
        }
    }
    .body{
        td{
            font-weight: 400;
            font-size: clamp(14px,2vw,16px);
            color: var(--a-hover);
        }
    }
}





.pricebtns{
    display: flex;
    flex-direction: row;
    padding: 6px;
    background: var(--white);
    box-shadow: 0px 0px 8px -1px rgba(65, 70, 192, 0.2);
    border-radius: 4px;
    max-width: 180px;
    button{
        color: var(--a-hover);
        font-weight: 500;
        font-size: clamp(10px,1vw,12px);
        // width: 100% !important;
        // max-width: 130px !important;
    }
    .select{
    color: var(--primary) !important;
    background: var(--primary-light);
}
}








.seprater{
    height: 100%;
    width: .5px;
    border-left: 1px solid var(--primary);
}




.coin{
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    align-items: center;
    img{
        width: 36px;
        height: 36px;
    }
    .unit{
        display: flex;
        flex-direction: column;
        row-gap: 4px;
    }
    .faunit{
                    color: var(--black);
                    font-weight: 500;
                    font-size: clamp(14px,2vw,16px);
    }
    .enunit{
                    color: rgba(132, 110, 110, 1);
                    font-weight: 400;
                    font-size: clamp(10px,1vw,12px);
    }
}
.header-container{
    display: flex;
    flex-direction: row;
    column-gap: 36px;
    width: 80%;
    justify-content: space-between;
    .show-coin{
        justify-content: space-between;
    }
}
@media only screen and(max-width:750px){
        .header-container{
            flex-direction: column;
            row-gap: 10px;
        }
}
@media only screen and(max-width:400px){
        .remove2{
            display: none;
        }
}
</style>