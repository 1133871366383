import Forms from '../components/Auth/Main.vue';



export default [
    {
        path :'/',
        name :'HomePage',
        component: () => import('../components/HomePage/Main.vue')
    },
    {
        path :'/aboutus',
        name :'AboutUs',
        component: () => import('../components/PublicPages/AboutUs.vue')
    },
    {
        path :'/faq',
        name :'FAQ',
        component: () => import('../components/PublicPages/FAQ.vue')
    },
    {
        path :'/privacypolicy',
        name :'PrivacyPolicy',
        component: () => import('../components/PublicPages/PrivacyPolicy.vue')
    },
    {
        path :'/notifications',
        name :'Notifications',
        component: () => import('../components/PublicPages/Notifications.vue'),
    },
    {
        path :'/guide',
        name :'Guide',
        component: () => import('../components/PublicPages/Guide.vue')
    },
    {
        path :'/fees',
        name :'Fees',
        component: () => import('../components/PublicPages/Fee.vue')
    },
    {
        path :'/market',
        name :'Market',
        component: () => import('../components/PublicPages/Market.vue')
    },
    {
        path :'/register',
        name :'Register',
        component: Forms
    },
    {
        path :'/otplogin/:authType',
        name :'OtpLogin',
        component: Forms,
        props:true,
    },
    {
        path :'/login',
        name :'Login',
        component: Forms
    },
    {
        path :'/forgetpass',
        name :'ForgetPass',
        component: Forms
    },
    {
        path :'/forgetotp/:authType',
        name :'OtpForget',
        component: Forms,
        props:true,
    },
    {
        path :'/newpass',
        name :'NewPass',
        component: Forms
    },
    {
        path :'/advancetrade/:tradeTo/:tradeFrom',
        name :'AdvanceTrade',
        component: () => import('../components/P2P/Main.vue'),
        props:true
    },
    {
        path: '/advancetrade',
        // name: 'AdvanceTrade',
        redirect: '/advancetrade/TOMAN/BITCOIN',
    },
    {
        path :'/exchange',
        name :'Exchange',
        component: () => import('../components/OTC/Main.vue'),
    },
    {
        path :'/dashboard',
        component: () => import('../components/Panel/Main.vue'),
        children : [
            {
                path :'',
                name :'Dashboard',
                component: () => import('../components/Panel/Dashboard/Main.vue'),
            },
            {
                path : 'finotech',
                name:'FinoTech',
                component:() => import('../components/Panel/KYC/KYC.vue'),
            },
            {
                path :'wallet',
                name :'Wallet',
                component: () => import('../components/Panel/WalletP2P/Main.vue'),
            },
            {
                path :'ExchangeWallet',
                name :'ExchangeWallet',
                component: () => import('../components/Panel/WalletExchange/Main.vue'),
            },
            // {
            //     path :'Activeorders',
            //     name :'Activeorders',
            //     component: () => import('../components/Panel/ActiveOrdersa/Main.vue'),
            // },
            {
                path :'account',
                name :'Account',
                component: () => import('../components/Panel/KYC/Main.vue'),
            },
            {
                path :'validation',
                name :'Validation',
                component: () => import('../components/Panel/KYC/Main.vue'),
            },
            {
                path :'history',
                name :'History',
                component: () => import('../components/Panel/History/Main.vue'),
            },
            {
                path :'tickets',
                name :'Tickets',
                component: () => import('../components/Panel/Tickets/Main.vue'),
            },
            {
                path :'setting',
                name :'Setting',
                component: () => import('../components/Panel/Settings/Main.vue'),
            },
            {
                path :'invitefriends',
                name :'InviteFriends',
                component: () => import('../components/Panel/InviteFriends/Main.vue'),
            },

            {
                path: '*',
                redirect: '/'
            }
        ]
    },
    {
        path:'*',
        redirect:'/'
    },
];