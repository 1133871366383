<template>
    <div :class="{'p2p-nav':$route.name=='AdvanceTrade'}" class="navbar-container">
        <transition name="ham-fade" >
            <div v-if="hamMenuShow" class="ham-menu">
                <a @click.prevent="reDirect('AdvanceTrade')" href="" class="item"> معامله پیشرفته </a>
                <a @click.prevent="reDirect('Exchange')" href="" class="item"> معامله سریع (OTC) </a>
                <a @click.prevent="reDirect('Market')" href="" class="item"> قیمت رمز ارز ها </a>
                <a @click.prevent="reDirect('Fees')" href="" class="item"> کارمزد و سطوح کاربری </a>
                <a v-if="!state.userInfo.fullName" @click.prevent="reDirect('Login')" href=""> ورود </a>
                <a v-if="!state.userInfo.fullName" @click.prevent="reDirect('Register')" href=""> عضویت </a>
            </div>
        </transition>
        <div v-if="$route.name !=='AdvanceTrade'" class="logo-items">
            <div class="logo">
                <div @click.prevent="hamMenuShow = !hamMenuShow" class="hambtn-container">
                    <span :class="{'top':hamMenuShow}" class=""></span>
                    <span :class="{'remove':hamMenuShow}" ></span>
                    <span :class="{'bottom':hamMenuShow}" class=""></span>
                </div>
                <img @click.self="$router.push('/')" src="../../assets/Logo.svg" alt="">
                <span @click.self="$router.push('/')" class="logo-span"> کندل </span>
            </div>
            <div class="nav-items">
                <a @click.prevent="$router.push('/advancetrade')" href="" class="item"> معامله پیشرفته </a>
                <a @click.prevent="$router.push('/exchange')" href="" class="item"> معامله سریع (OTC) </a>
                <a @click.prevent="$router.push('/market')" href="" class="item"> قیمت رمز ارز ها </a>
                <a @click.prevent="$router.push('/fees')" href="" class="item"> کارمزد و سطوح کاربری </a>
            </div>
        </div>
        <Header :key="$route.params.tradeTo + $route.params.tradeFrom + 'hdr'" v-if="$route.name =='AdvanceTrade'"/>
        <div class="btns">
            <div v-if="$route.name =='AdvanceTrade'" @click="hamMenuShow = !hamMenuShow" class="hambtn-container">
                    <span :class="{'top':hamMenuShow}" class=""></span>
                    <span :class="{'remove':hamMenuShow}" ></span>
                    <span :class="{'bottom':hamMenuShow}" class=""></span>
            </div>
            <transition name="drop-fade" >
                <div v-if="showDropDown" class="dropdown-container">
                    <div @click="reDirect('Dashboard')" class="drop-item">
                        <img src="../../assets/Icons/dashIcon.svg" alt="">
                        <span> داشبورد </span>
                    </div>
                    <div @click="reDirect('Wallet')" class="drop-item">
                        <img src="../../assets/Icons/walletIcon.svg" alt="">
                        <span> کیف پول </span>
                    </div>
                    <div @click="reDirect('History')" class="drop-item">
                        <img src="../../assets/Icons/historyIcon.svg" alt="">
                        <span> تاریخچه </span>
                    </div>
                    <div @click="reDirect('Account')" class="drop-item">
                        <inline-svg class="drop-svg" :src="require('../../assets/Icons/profileIcon.svg')" />
                        <span> پروفایل </span>
                    </div>
                    <div @click="reDirect('Setting')" class="drop-item">
                        <img src="../../assets/Icons/settingsIcon.svg" alt="">
                        <span> تنظیمات </span>
                    </div>
                    <!-- <div @click="reDirect('InviteFriends')" class="drop-item">
                        <img src="../../assets/Icons/inviteIcon.svg" alt="">
                        <span> دعوت </span>
                    </div> -->
                    <div @click="reDirect('Exit')" class="drop-item">
                        <img src="../../assets/Icons/exitIcon.svg" alt="">
                        <span class="exit-span"> خروج </span>
                    </div>
                </div>
            </transition>
            <img @click="$router.push('/')" class="svg" style="cursor: pointer;" src="../../assets/Icons/homePage.svg" alt="">
            <img style="cursor: pointer;" class="svg" src="../../assets/Icons/rayChat.svg" alt="">
            <div v-if="state.token" @click="$router.push('/notifications')" class="notifalert">
                <img src="../../assets/Icons/navNotifs.svg" alt="">
                <span v-if="state.notification>0" class="notif-num"> {{state.notification}} </span>
            </div>
            <div style="cursor:pointer" @click="showDropDown = !showDropDown" v-if="state.token" class="name-forms-btns">
                <inline-svg class="svg" :src="require('../../assets/Icons/profileIcon.svg')" />
                <span class="name"> {{state.userInfo.firstName + ' ' + state.userInfo.lastName}} </span>
            </div>
            <div v-if="!state.token" class="name-forms-btns">
                <div  class="forms-btns">
                    <a @click.prevent="$router.push('/login')" href=""> ورود </a>
                    <span> | </span>
                    <a @click.prevent="$router.push('/register')" href=""> عضویت </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Header from '../P2P/Header.vue';
    export default {
        name:'Navbar',
        components:{
            Header,
        },
        data() {
            return {
                hamMenuShow:false,
                notifs:2,
                showDropDown:false,
            }
        },
        methods:{
            reDirect(e){
                this.showDropDown=false;
                if(e=='Exit'){
                    this.$logout();
                }else if(e=='AdvanceTrade'){
                    this.$router.push('/advancetrade');
                }else{    
                    this.$router.push({name:e});
                }
                this.hamMenuShow=false

            }
        },
        created : function(){
            window.addEventListener("click" , (event) => {
                if(!event.target.closest('.dropdown-container,.name-forms-btns')){
                    this.showDropDown=false
                }
            })
            window.addEventListener("click" , (event) => {
                if(!event.target.closest('.ham-nenu,.hambtn-container')){
                    this.hamMenuShow=false;
                }
            })
        },
        
    }
</script>

<style lang="scss" scoped>
.drop-svg{
    fill: var(--a-hover);
    width: 19px;
    height: 16px;
}
.svg{
    z-index: 12;
    width: 20px;
    height: 20px;
}
.p2p-nav{
    padding: 10px 24px !important;
    .btns{
        justify-content: flex-start !important;
    }
}



.dropdown-container{
    box-shadow: 0px 0px 8px -1px rgba(65, 70, 192, 0.2);
    background: rgba(240, 240, 245, 1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 24px;
    padding: 24px;
    border-radius: 12px;
    position: absolute;
    top: 60px;
    left: 0;
    z-index: 99;
    overflow: hidden;
    .drop-item{
        display: flex;
        flex-direction: row;
        column-gap: 16px;
        align-items: center;
        opacity:.7 ;
        color: var(--grey-light);
        cursor: pointer;
        transition: 400ms;
        &:hover{
            opacity: 1;
        }
        .exit-span{
            color: var(--red);
        }
    }
}
.ham-menu{
    z-index: 100;
    position: fixed;
    right: 0;
    top: 0;
    width: 30%;
    display: flex;
    flex-direction: column;
    padding: 60px 10px;
    background: var(--ham-bg);
    height: 100vh;
    align-items: center;
    text-align: center;
    row-gap: 30px;
}
.remove{
    display: none;
}
.top{
    transform: rotate(45deg) translateX(6px);
}
.bottom{
    transform: rotate(-45deg) translateX(6.5px);
}
.hambtn-container{
    align-self: flex-start;
    z-index: 101;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 5px;
    span{
        width: 25px;
        border-radius: 12px;
        border: 2px solid var(--a-default);
        transition: 600ms;
    }
}
.navbar-container{
    position: fixed;
    z-index: 80;
    top: 0;
    padding: 18px 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    background: var(--nav-bg);
    backdrop-filter: blur(70px);
    opacity: 0.95;
    .logo-items{
        font-weight: 400;
        font-size: clamp(14px,2vw,16px);
        display: flex;
        flex-direction: row;
        column-gap: 70px;
        // justify-content: space-between;
        // column-gap: 24px;
        // width: 55%;
        align-items: center;
        .logo{
            cursor: pointer;
            align-items: center;
            display: flex;
            flex-direction: row;
            column-gap: 12px;
            width: 10%;
            img{
                width: 26px;
                height: 26px;
            }
        }
        .nav-items{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            column-gap: 24px;
        }
    }
    .btns{
        display: flex;
        flex-direction: row;
        column-gap: 36px;
        align-items: center;
        position: relative;
        .notifalert{
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            .notif-num{
                width: 18px;
                height: 18px;
                background: var(--red);
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                transform: translate(25px,-5px);
                color: white;
                font-size: clamp(10px,1vw,12px);
                font-weight: 400;
            }
        }
        .name-forms-btns{
            display: flex;
            flex-direction: row;
            column-gap: 12px;
            align-items: center;
            .forms-btns{
                a{
                    &:hover{
                        border-bottom: 1px solid transparent;
                    }
                }
            }
        }
    }
}
@media only screen and(max-width:1200px){
    .p2p-nav{
        flex-direction: column;
        row-gap: 0px;
        padding: 0px !important;
        .header-container{
            padding: 10px !important;
            order: 2;
            justify-content: space-between;
            width: 100%;
            background: var(--white) ;
        }
        .btns{
            padding: 10px !important;
            justify-content: end;
            width: 100%;
        }
    }
}
@media only screen and(max-width:1000px){
    .nav-items{
        display: none !important;
    }
    .logo-items{
        column-gap: 24px !important;
    }
}
@media only screen and(max-width:500px){
    .forms-btns{
        display: none !important;
    }
}
@media only screen and(max-width:800px){
    .ham-menu{
        width: 50%;
    }
    .btns{
        width: 55%;
    }
}
@media only screen and(max-width:500px){
    .name-forms-btns{
        .name{
            display: none !important;
        }
    }
    .ham-menu{
        width: 70%;
    }

}
@media only screen and(min-width:1000px){
    .hambtn-container{
        display: none;
    }
    .ham-menu{
        display: none;
    }
}

</style>