<template>
    <form @submit.prevent="submit" class="register-container">
        <h2> تغییر رمز عبور </h2>
        <div class="inputs-container">




            
            <div class="label-container">
                <div :class="newPassWarn? 'div-error' : (fnewpass? 'div-active':'')" class="input">
                    <label for="input">
                        <span :class="newPassWarn? 'span-error' : (fnewpass || data.password? 'span-active':'')"> رمز عبور جدید </span>
                        <input @focus="fnewpass=true" v-model="data.password" :type="showPass ? 'text':'password'">
                    </label>
                    <inline-svg @click="showPass=true" v-if="!showPass" :class="newPassWarn? 'svg-error' : (fnewpass? 'svg-active':'')" class="svg pass-svg" :src="require('../../assets/Icons/seePass.svg')" />
                    <inline-svg @click="showPass=false" v-if="showPass" :class="newPassWarn? 'svg-error' : (fnewpass? 'svg-active':'')" class="svg pass-svg" :src="require('../../assets/Icons/dontSeePass.svg')" />
                </div>
                <span v-if="newPassWarn" class="warn"> {{newPassWarn}} </span>
            </div>






            <div class="label-container">
                <div :class="passRepeatWarn? 'div-error' : (fnewpassrepeat? 'div-active':'')" class="input">
                    <label for="input">
                        <span :class="passRepeatWarn? 'span-error' : (fnewpassrepeat || data.newpassrepeat? 'span-active':'')"> تکرار رمز عبور جدید </span>
                        <input @focus="fnewpassrepeat=true" v-model="data.newpassrepeat" :type="showNewPass ? 'text':'password'">
                    </label>
                    <inline-svg @click="showNewPass=true" v-if="!showNewPass" :class="passRepeatWarn? 'svg-error' : (fnewpassrepeat? 'svg-active':'')" class="svg pass-svg" :src="require('../../assets/Icons/seePass.svg')" />
                    <inline-svg @click="showNewPass=false" v-if="showNewPass" :class="passRepeatWarn? 'svg-error' : (fnewpassrepeat? 'svg-active':'')" class="svg pass-svg" :src="require('../../assets/Icons/dontSeePass.svg')" />
                </div>
                <span v-if="passRepeatWarn" class="warn"> {{passRepeatWarn}} </span>
            </div>








        </div>


        <div class="btns">
            <button :disabled="!data.password || !data.newpassrepeat || newPassWarn || passRepeatWarn" class="submit"> ثبت و ادامه </button>
        </div>


    </form>
</template>

<script>
    export default {
        name:'Login',
        data() {
            return {
                showPass:false,
                showNewPass:false,
                fnewpass:false,
                fnewpassrepeat:false,
                data: {
                    password:'',
                    newpassrepeat:'',

                },
            }
        },
        computed:{
            newPassWarn(){
                let err
                if(this.fnewpass){
                if(!this.data.password){
                    err='رمز عبور جدید را وارد کنید'
                }else if(!this.$validPass(this.data.password) || this.data.password.length < 8){
                    err='رمز عبور باید حداقل ۸رقم و شامل یک حرف کوچک و یک حرف بزرگ باشد'
                } else{
                    err = false
                }
                }else{
                    err = false
                }
                return err
            },
            passRepeatWarn(){
                let err
                if(this.fnewpassrepeat){
                if(!this.data.newpassrepeat){
                    err='تکرار رمز عبور جدید را وارد کنید'
                }else if(this.data.password !== this.data.newpassrepeat){
                    err='تکرار رمز عبور با رمز عبور اولیه تطابق ندارد'
                } else{
                    err = false
                }
                }else{
                    err = false
                }
                return err
            },
        },
        methods: {
            async submit() {
                if (!this.passRepeatWarn && !this.newPassWarn) {
                    this.state.loading = true
                    const headers = {"Authorization": `Bearer ${this.state.otptoken}`,}
                    let [res,] = await this.$http.post('/users/v2/reset-password',this.data,{headers},this.data,{headers})
                    if (res) {
                        this.$error('رمز شما با موفقیت تغییر کرد', '', 'success')
                        await this.$router.push('/Login')
                    }
                }
            },
        },
        created:function(){
            window.addEventListener("click",event => {
                if(!event.target.closest('.label-container')){
                    this.femail=false
                }
            })
        }

    }
</script>

<style lang="scss" scoped>
.forgetpass{
    color: var(--primary);
    font-weight: 500;
    font-size: clamp(14px,2vw,16px);
}




.go-login{
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: clamp(12px,1vw,14px);
    a{
        color: var(--primary);
    }
}




.btns{
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    width: 100%;
    align-items: center;
    .submit{
        width: 100%;
    }
    .google{
        width: 100%;
        background: none !important;
        color: var(--primary) !important;
        border: 1px solid var(--primary);
    }
}






.label-container{
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    .pass-svg{
        cursor: pointer;
    }
    .svg{
        width: 24px !important;
        height: 24px !important;
    }
    .warn{
        color: var(--red);
        font-weight: 300;
        font-size: clamp(10px,1vw,12px);
    }
    .svg-active{
        fill: var(--primary);
    }
    .svg-error{
        fill: var(--red) !important;
    }
    .div-active{

            opacity: 1 !important;
            box-shadow: inset 0px -2px 0px #6E78FF;
    }
    .div-error{
            opacity: 1 !important;
            box-shadow: inset 0px -2px 0px var(--red);
    }

}



.inputs-container{
    display: flex;
    flex-direction: column;
    row-gap: 23px;
    width: 100%;
    padding-bottom: 18px;
    .title{
        font-weight: 500;
        font-size: clamp(14px,2vw,16px);
        color: var(--black);
    }
}



.register-container{
    width: 100%;
    background: var(--homepage-bg);
    box-shadow: 0px 0px 8px -1px rgba(65, 70, 192, 0.2);
    border-radius: 12px;
    padding: 24px 12px;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    align-items: flex-start;
    h2{
        color: var(--darker-primary);
        font-weight: 700;
        font-size: clamp(24px,3vw,28px);
        align-self: center;
    }
}
@media only screen and(max-width:1700px){
    .register-container{
        max-width: 500px;
    }
}
</style>