<template>
    <div class="footer-container">
        <div class="content-container">
            <div class="explanations">
                <div class="title">
                    <div class="logo">
                        <img src="../../assets/Logo.svg" alt="">
                        <span> کندل </span>
                    </div>
                    <p class="abouttext">
                        شرکت نرم افزاری ویستا فعال در عرصه خدماتی همچون طراحی <br />
                        وبسایت، سئو و بهینه سازی، طراحی اپلیکیشن های موبایلی <br />
                         و نرم افزار های تحت وب با کمترین هزینه پوشش دهد<br />
                    </p>
                </div>
                <div class="social-media">
                    <h3> مارا در شبکه های اجتماعی دنبال کنید </h3>
                    <div class="icons">
                        <a :href="info.facebook"><img  class="social-icons" src="../../assets/Icons/faceBook.svg" alt=""></a>
                        <a :href="info.telegram"><img class="social-icons" src="../../assets/Icons/twitter.svg" alt=""></a>
                        <a :href="info.instagram"><img class="social-icons" src="../../assets/Icons/instagram.svg" alt=""></a>
                    </div>
                </div>
            </div>
            <div class="items">
                <div class="item">
                    <span class="title">
                        خدمات ما
                    </span>
                    <span @click.prevent="reDirect('/market')" class="body">
                        بازار ارز دیجیتال
                    </span>
                    <span @click.prevent="reDirect('/exchange')" class="body">
                        معامله سریع
                    </span>
                </div>
                <div class="item">
                    <span  class="title">
                        راهنمای سایت
                    </span>
                    <span @click.prevent="reDirect('/guide')" class="body">
                        راهنمای سایت
                    </span>
                    <span @click.prevent="reDirect('/fees')" class="body">
                        کارمزد و سطوح کاربری
                    </span>
                    <span @click.prevent="reDirect('/faq')" class="body">
                        پرسش های متداول
                    </span>
                </div>
                <div class="item">
                    <span class="title">
                        درباره ما
                    </span>
                    <span @click.prevent="reDirect('/aboutus')" class="body">
                        درباره ما
                    </span>
                    <span @click.prevent="reDirect('/privacypolicy')" class="body">
                        قوانین سایت
                    </span>
                    <span @click.prevent="reDirect('/privacypolicy')" class="body">
                        حریم خصوصی
                    </span>
                </div>
                <div class="item">
                    <span class="title">
                        تماس با ما
                    </span>
                    <div class="contact-container">
                        <img src="../../assets/Icons/phone.svg" alt="">
                        <span class="body"> {{info.number}} </span>
                    </div>
                    <div class="contact-container">
                        <inline-svg class="svg" :src="require('../../assets/Icons/Email.svg')" alt="" />
                        <span class="body"> {{info.email}} </span>
                    </div>
                    <div class="contact-container">
                        <img src="../../assets/Icons/location.svg" alt="">
                        <span class="body"> {{info.address}} </span>
                    </div>
                </div>
            </div>
        </div>
        <p class="vistaright">
            تمامی حقوق شرکت ویستا محفوظ است و استفاده از مطالب ویستا تنها با ذکر نام ویستا و درج لینک مستقیم مجاز است
        </p>
    </div>
</template>

<script>
export default {
    name:'Footer',
    computed:{
        info(){
            let inf = this.state.info
            return inf
        }
    },
    methods:{
        reDirect(target){
            this.$router.push(target);
            window.scrollTo(0, 0);
        },
    },
}
</script>
<style lang="scss" scoped>
.social-icons{
    cursor: pointer;
}
.contact-container{
    display: flex;
    flex-direction: row;
    column-gap: 8px;
}
.items{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 65%;
    column-gap: 20px;
    flex-wrap: wrap;
    .item{
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        .title{
            color: var(--darker-primary);
            font-weight: 400;
            font-size: clamp(14px,1vw,16px);
        }
        .body{
            color: var(--black);
            font-weight: 300;
            font-size: clamp(12px,1vw,14px);
            cursor: pointer;
        }

    }
}
.explanations{
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    justify-content: start;
    align-items: flex-start;
    width: 22%;
    .title{
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        .logo{
            display: flex;
            flex-direction: row;
            column-gap: 12px;
                span{
                    color: var(--dark-primary);
                    font-weight: 400;
                    font-size: clamp(14px,2vw,16px);
                }
        }
        .abouttext{
            color: var(--black);
            font-weight: 400;
            font-size: clamp(10px,1vw,12px);
        }

    }
    .social-media{
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        h3{
            color: var(--darker-primary);
            font-size: clamp(14px , 2vw , 16px) !important;
            font-weight: 400 !important;
        }
        .icons{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 50%;
        }
    }
}
.content-container{
    z-index: 3;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.vistaright{
    color: var(--black);
    font-weight: 400;
    font-size: clamp(10px,1vw,12px);
    align-self: flex-start;
    z-index: 3;
}
.footer-container{
    padding-top: 200px !important;
    display: flex;
    flex-direction: column;
    row-gap: 64px;
    justify-content: space-between;
    padding: 40px 120px;
    align-items: center;
    position: relative;
    background: var(--homepage-bg);
    &::before{
        content: ' ';
        width: 100%;
        height: 600px;
        position: absolute;
        background: url('../../assets/footerBG.png');
        background-size: 100%;
        background-position: bottom;
        background-repeat: no-repeat;
        bottom: 0;
    }
}
@media only screen and(max-width:1000px){
    .footer-container{
        padding: 20px !important;
    }
    .content-container{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 30px;

    }
    .items{
        width: 100%;
    }
    .explanations{
        width: 100%;
        align-items: center;
        justify-content: center;
    }
}
</style>