<template>
    <form @submit.prevent="submit" class="register-container">
        <h2> ثبت نام </h2>
        <div class="inputs-container">


            <div class="label-container">
                <div :class="emailWarn? 'div-error' : (femail? 'div-active':'')" class="input">
                    <label for="input">
                        <span :class="emailWarn? 'span-error' : (femail || data.email? 'span-active':'')"> آدرس ایمیل </span>
                        <input @focus="femail=true" v-model="data.email" type="text">
                    </label>
                    <inline-svg :class="emailWarn? 'svg-error' : (femail? 'svg-active':'')" class="svg" :src="require('../../assets/Icons/Email.svg')" />
                </div>
                <span v-if="emailWarn" class="warn"> {{emailWarn}} </span>
            </div>


            <div class="label-container">
                <div :class="passWarn? 'div-error' : (fpass? 'div-active':'')" class="input">
                    <label for="input">
                        <span :class="passWarn? 'span-error' : (fpass || data.password? 'span-active':'')"> رمز عبور </span>
                        <input @focus="fpass=true" v-model="data.password" :type="showPass ? 'text':'password'">
                    </label>
                    <inline-svg @click="showPass=true" v-if="!showPass" :class="passWarn? 'svg-error' : (fpass? 'svg-active':'')" class="svg pass-svg" :src="require('../../assets/Icons/seePass.svg')" />
                    <inline-svg @click="showPass=false" v-if="showPass" :class="passWarn? 'svg-error' : (fpass? 'svg-active':'')" class="svg pass-svg" :src="require('../../assets/Icons/dontSeePass.svg')" />
                </div>
                <span v-if="passWarn" class="warn"> {{passWarn}} </span>
            </div>

            <div class="label-container">
                <div :class="fInivteCode? 'div-active':''" class="input">
                    <label for="input">
                        <span :class="fInivteCode || data.referralCode ? 'span-active':''"> کد دعوت (اختیاری) </span>
                        <input @focus="fInivteCode=true" v-model="data.referralCode" type="text">
                    </label>
                    <!-- <inline-svg :class="emailWarn? 'svg-error' : (femail? 'svg-active':'')" class="svg" :src="require('../../assets/Icons/Email.svg')" /> -->
                </div>
                <!-- <span v-if="emailWarn" class="warn"> {{emailWarn}} </span> -->
            </div>

        </div>
        <div class="accept-terms">
            <div class="checkbox-container">
                <span @click="checked = !checked" :class="{'checked':checked}" class="checkbox"></span>
                <span class="text">
                    <a @click.prevent="$router.push('/privacypolicy')" href=""> قوانین سایت </a>
                    را خوانده و پذیرفته ام
                </span>
            </div>
            <img src="../../assets/recaptcha.png" alt="" class="recaptcha">
        </div>
        <div class="btns">
            <button :disabled="disable" class="submit"> ثبت نام </button>
            <span> یا </span>
            <button class="google"> ثبت نام از طریق گوگل </button>
        </div>
        <span class="go-login">
                    قبلا ثبت نام کرده اید ؟
                    <a @click.prevent="$router.push('/login')" href=""> وارد شوید </a>
        </span>
    </form>
</template>

<script>
const goto = {
        EMAIL: '/otplogin/EMAIL',
        SMS: '/otpform/SMS',
        GOOGLE_AUTHENTICATOR: '/otpform/GOOGLE_AUTHENTICATOR',
}
    export default {
        name:'Register',
        data() {
            return {
                femail:false,
                fpass:false,
                showPass:false,
                fInivteCode:false,
                checked:false,
                data: {
                    email: '',
                    password: '',
                    osType: '',
                    deviceType: '',
                    browser: '',
                    referralCode: ''
                },
            }
        },
        computed:{
            disable(){
                return !this.data.email || !this.data.password || !this.checked || this.emailWarn || this.passWarn
            },
            emailWarn(){
                let err
                if(this.femail){
                if(!this.data.email){
                    err='ایمیل را وارد کنید'
                }else if(!this.$validEmail(this.data.email)){
                    err='ایمیل صحیح را وارد کنید'
                } else{
                    err = false
                }
                }else{
                    err = false
                }
                return err
            },
            passWarn(){
                let err
                if(this.fpass){
                if(!this.data.password){
                    err='رمز عبور را وارد کنید'
                }else if(!this.$validPass(this.data.password) || this.data.password.length <8){
                    err='رمز عبور باید حداقل ۸رقم و شامل یک حرف کوچک و یک حرف بزرگ باشد'
                } else{
                    err = false
                }
                }else{
                    err = false
                }
                return err
            }
        },
        methods: {
            async submit() {
                this.state.loading = true
                this.data = {
                ...this.data,
                osType: this.$detectOs(),
                deviceType: this.$detectDevice(),
                browser: this.$detectBrowser() || 'CHROME'
                }
                const res = await this.$axios.post('/users/register', this.data)
                if (res.message === 'user created successfully , verification email sent') {
                    if (res.baseDTO.towStepAuthenticationType === 'EMAIL') {
                        this.$cookies.set('username', this.data.email)
                        this.state.userInfo.email = this.data.email
                        await this.$router.push(goto[res.baseDTO.towStepAuthenticationType])
                    }
                } else if (res.token) {
                    this.$setToken(res.token, true)
                    await this.$router.push({ name: 'Validation' })
                }
        },
        },
        created:function(){
            window.addEventListener("click",event => {
                if(!event.target.closest('.label-container')){
                    this.femail=false
                    this.fpass=false
                    this.fInivteCode=false
                }
            })
        }

    }
</script>

<style lang="scss" scoped>
.go-login{
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: clamp(12px,1vw,14px);
    a{
        color: var(--primary);
    }
}



.btns{
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    width: 100%;
    align-items: center;
    .submit{
        width: 100%;
    }
    .google{
        width: 100%;
        background: none !important;
        color: var(--primary) !important;
        border: 1px solid var(--primary);
    }
}




.accept-terms{
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    justify-content: start;
    align-items: flex-start;
    width: 100%;
    .recaptcha{
        width: 40%;
    }
    .checkbox-container{
        display: flex;
        flex-direction: row;
        column-gap: 4px;
        align-items: center;
        .checkbox{
            width: 14px;
            height: 14px;
            border: 1px solid #B2A7A7;
            border-radius: 3px;
            cursor: pointer;
        }
        .checked{
            background: var(--primary);
            border: 1px solid var(--primary);
        }
        .text{
            a{
                color: var(--primary);
            }
            font-weight: 400;
            font-size: clamp(10px,1vw,12px);
        }
    }
}




.label-container{
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    .pass-svg{
        cursor: pointer;
    }
    .svg{
        width: 24px !important;
        height: 24px !important;
    }
    .warn{
        color: var(--red);
        font-weight: 300;
        font-size: clamp(10px,1vw,12px);
    }
    .svg-active{
        fill: var(--primary);
    }
    .svg-error{
        fill: var(--red) !important;
    }
    .div-active{

            opacity: 1 !important;
            box-shadow: inset 0px -2px 0px #6E78FF;
    }
    .div-error{
            opacity: 1 !important;
            box-shadow: inset 0px -2px 0px var(--red);
    }
    .input{
        transition: 700ms;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        column-gap: 4px;
        align-items: center;
        padding: 14px 16px;
        border-radius: 8px 8px 0px 0px;
        background: #DBDCE8;
        width: 100%;
        opacity: .7;
        max-height: 50px;
        &:hover{
            opacity: 1;
        }
        .svg{
            width: 20px;
            height: 16px;
        }
        label{
            width: 90%;
            display: flex;
            flex-direction: column;
            row-gap: 3px;
            position: relative;
            z-index: 1;
            input{
                transform: translateY(10px);
                background: transparent;
                z-index: 3;
                color: var(--black);
                font-weight: 400;
                font-size: clamp(14px,2vw,16px);
            }
            span{
                position: absolute;
                color: var(--a-hover);
                font-weight: 400;
                font-size: clamp(14px,2vw,16px);
                z-index: 2;
                transition: 700ms;
            }
            .span-active{
                transform: translate(15px,-11px) scale(0.7,0.7);
                color: var(--primary) !important;
            }
            .span-error{
                transform: translate(15px,-11px) scale(0.7,0.7);
                color: var(--red) !important;
            }
        }
    }

}



.inputs-container{
    display: flex;
    flex-direction: column;
    row-gap: 23px;
    width: 100%;
    padding-bottom: 18px;
}



.register-container{
    width: 100%;
    background: var(--homepage-bg);
    box-shadow: 0px 0px 8px -1px rgba(65, 70, 192, 0.2);
    border-radius: 12px;
    padding: 24px 12px;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    align-items: flex-start;
    h2{
        color: var(--darker-primary);
        font-weight: 700;
        font-size: clamp(24px,3vw,28px);
        align-self: center;
    }
}
@media only screen and(max-width:1700px){
    .register-container{
        max-width: 500px;
    }
}
</style>