import Vue from 'vue'
import App from './App.vue'
import router from './Router/Index';
import './Styles/Fonts/iran-sans.scss';
import './Styles/Main.scss';
import PerfectScrollbar from '@nima2142/vue2-perfect-scrollbar'
import '@nima2142/vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import VuePersianDatetimePicker from 'vue-persian-datetime-picker';
import TrendChart from "vue-trend-chart";
import InlineSvg from 'vue-inline-svg';
import VueCookies from 'vue-cookies'
import VueApexCharts from 'vue-apexcharts'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import './Lib/state';
import './Lib/axios';
import './Lib/globalConstants';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import './Lib/globalFunctions';


Vue.config.productionTip = false
Vue.use(VueApexCharts);
Vue.use(VueCookies)
Vue.use(VueSweetalert2);
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('date-picker', VuePersianDatetimePicker);
Vue.component('inline-svg', InlineSvg);
Vue.component('ApexCharts', VueApexCharts)
Vue.use(TrendChart);
Vue.use(PerfectScrollbar,{
  options:{
      // swipeEasing: false,
      wheelPropagation: false,
  }
});

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
