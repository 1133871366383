<template>
    <form @submit.prevent="submit" class="register-container">
        <h2> فراموشی رمز عبور </h2>
        <div class="inputs-container">
            <span class="title">
                ایمیلی که با آن ثبت نام کرده اید را وارد کنید
            </span>
            <div class="label-container">
                    <div :class="emailWarn? 'div-error' : (femail? 'div-active':'')" class="input">
                        <label for="input">
                            <span :class="emailWarn? 'span-error' : (femail || otp.email? 'span-active':'')"> آدرس ایمیل </span>
                            <input @focus="femail=true" v-model="otp.email" type="text">
                        </label>
                        <inline-svg :class="emailWarn? 'svg-error' : (femail? 'svg-active':'')" class="svg" :src="require('../../assets/Icons/Email.svg')" />
                    </div>
                    <span v-if="emailWarn" class="warn"> {{emailWarn}} </span>
            </div>

        </div>


        <div class="btns">
            <button :disabled="!otp.email || emailWarn" class="submit"> ثبت و ادامه </button>
        </div>


    </form>
</template>

<script>
const goto = {
        EMAIL: '/forgetotp/EMAIL',
        SMS: '/forgetotp/SMS',
        GOOGLE_AUTHENTICATOR: '/forgetotp/GOOGLE_AUTHENTICATOR',
}
    export default {
        name:'Login',
        data() {
            return {
                femail:false,
                otp:{
                    email:''
                },
            }
        },
        computed:{
            emailWarn(){
                let err
                if(this.femail){
                if(!this.otp.email){
                    err='ایمیل را وارد کنید'
                }else if(!this.$validEmail(this.otp.email)){
                    err='ایمیل صحیح را وارد کنید'
                } else{
                    err = false
                }
                }else{
                    err = false
                }
                return err
            },
        },
        methods:{
            async submit(){
                    this.state.loading = true
                    const res = await this.$axios.post('/users/forget-password?email=' + this.otp.email)
                    this.$cookies.set('username', this.otp.email)
                    this.state.userInfo.email = this.otp.email
                    if (res.baseDTO) {
                        this.state.userInfo.mobilenumber = res.baseDTO.starTwoStepAuthentication || '';
                        this.state.userInfo.towStepAuthenticationType = res.baseDTO.towStepAuthenticationType;
                    } else {
                        this.state.userInfo.towStepAuthenticationType = 'EMAIL'
                    }
                    await this.$router.push(goto[res.baseDTO.towStepAuthenticationType])
        }
        },
        created:function(){
            window.addEventListener("click",event => {
                if(!event.target.closest('.label-container')){
                    this.femail=false
                }
            })
        }

    }
</script>

<style lang="scss" scoped>
.forgetpass{
    color: var(--primary);
    font-weight: 500;
    font-size: clamp(14px,2vw,16px);
}




.go-login{
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: clamp(12px,1vw,14px);
    a{
        color: var(--primary);
    }
}




.btns{
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    width: 100%;
    align-items: center;
    .submit{
        width: 100%;
    }
    .google{
        width: 100%;
        background: none !important;
        color: var(--primary) !important;
        border: 1px solid var(--primary);
    }
}






.label-container{
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    .pass-svg{
        cursor: pointer;
    }
    .svg{
        width: 24px !important;
        height: 24px !important;
    }
    .warn{
        color: var(--red);
        font-weight: 300;
        font-size: clamp(10px,1vw,12px);
    }
    .svg-active{
        fill: var(--primary);
    }
    .svg-error{
        fill: var(--red) !important;
    }
    .div-active{

            opacity: 1 !important;
            box-shadow: inset 0px -2px 0px #6E78FF;
    }
    .div-error{
            opacity: 1 !important;
            box-shadow: inset 0px -2px 0px var(--red);
    }

}



.inputs-container{
    display: flex;
    flex-direction: column;
    row-gap: 23px;
    width: 100%;
    padding-bottom: 18px;
    .title{
        font-weight: 500;
        font-size: clamp(14px,2vw,16px);
        color: var(--black);
    }
}



.register-container{
    width: 100%;
    background: var(--homepage-bg);
    box-shadow: 0px 0px 8px -1px rgba(65, 70, 192, 0.2);
    border-radius: 12px;
    padding: 24px 12px;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    align-items: flex-start;
    h2{
        color: var(--darker-primary);
        font-weight: 700;
        font-size: clamp(24px,3vw,28px);
        align-self: center;
    }
}
@media only screen and(max-width:1700px){
    .register-container{
        max-width: 500px;
    }
}
</style>