<template>
    <div class="forms-container">
        <div class="forms">
            <Register v-if="$route.name=='Register'" />
            <Login v-if="$route.name=='Login'" />
            <ForgetPass v-if="$route.name=='ForgetPass'" />
            <Otp v-if="$route.name=='OtpRegister' ||$route.name=='OtpLogin' || $route.name=='OtpForget'" />
            <NewPass v-if="$route.name=='NewPass'" />
        </div>
        <div class="img-container">
                <img src="../../assets/coinsPic.png" alt="">
        </div>
    </div>
</template>

<script>
import Register from './Register.vue'
import Login from './Login.vue'
import ForgetPass from './ForgetPass.vue'
import NewPass from './NewPass.vue'
import Otp from './Otp.vue'
    export default {
  components: { 
                Register,
                Login,
                ForgetPass,
                NewPass,
                Otp
   },
        name:'AuthenticationsFroms'
    }
</script>

<style lang="scss" scoped>
.forms{
    width: 40%;
}
.forms-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: var(--homepage-bg);
    padding: 40px 120px;
    height: 100%;
    min-height: 100vh;
    &::before{
        content: ' ';
        background: var(--red);
        filter: blur(400px);
        opacity: .1;
        filter: drop-shadow(0 0 0.75rem var(--red));
        box-shadow: 0px 0px 100px 100px var(--red);
        background-size:cover;
        background-repeat: repeat-x;
        position: absolute;
        height: 300px;
        border-radius: 50%;
        width: 10%;
        left: 260px;
        top: 270px;
    }
}
@media only screen and(max-width:1200px){
    .forms-container::before{
        content: unset !important;
    }
    .img-container{
        display: none;
    }
    .forms{
        width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    }
}
@media only screen and(max-width:1200px){
    .forms-container{
        padding: 20px;
    }
}
@media only screen and(max-height:700px){
    .forms{
        margin-top: 100px;
    }
}
</style>